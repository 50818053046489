/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { GatsbyBrowser } from 'gatsby';
import React, { useState, useEffect } from 'react';
import { ViewportContext } from '@lws/page-components/dist/utils';
import { WindowEvents } from '@lsg/utils';
import '../styles/index.css';
import '@lsg/components/bundle/index.css';

/**
 * UpdateWrapper
 * keep checking for a view port size !== "none" and rerender if we got it
 */
function UpdateWrapper({ element }: { element: object }) {
  // run the resize event callbacks to make sure viewport is set
  // seems no longer necessary with new @lsg/utils
  // ResizeEvents.runCallbacks();

  // store current viewport
  const [currentViewport, updateViewport] = useState(
    'none' // we start with "none" viewport to pickup from SSR phase
  );

  // check if viewport changed and update state
  function checkViewport() {
    const newViewport =
      (window.getComputedStyle(document.body as any, ':after')
        .content as string).replace(/["']/g, '') || 'none';
    console.log('checker', newViewport);
    if (currentViewport !== newViewport) {
      WindowEvents.enableResizeEvents();
      window.requestAnimationFrame(() => updateViewport(newViewport));

      return true;
    }
    return newViewport !== 'none';
  }

  // listen on resize events and update in case the viewport changed
  useEffect(() => {
    // recurrently run checker as long as we have "none" viewport
    setTimeout(() => {
      // ResizeEvents.runCallbacks();
      console.log('check after timeout');
      if (!checkViewport()) {
        console.log('setting checker timer');
        const checker = setInterval(() => {
          // ResizeEvents.runCallbacks();
          if (checkViewport()) {
            console.log('clearing checker timer');
            clearInterval(checker);
          }
        }, 100);
      }
    });
    window.addEventListener('resize', checkViewport);
    return () => {
      window.removeEventListener('resize', checkViewport);
    };
  });

  // console.log('RENDER', ResizeEvents.getViewportSize());

  return (
    <ViewportContext.Provider value={currentViewport}>
      {element}
    </ViewportContext.Provider>
  );
}

export const gatsbyBrowser: GatsbyBrowser = {
  // we wrap the root element in a Update Wrapper, that calls the window resize callbacks
  // until the viewport size is available as we typically start with viewport "none" in SSR
  // also rerenders the app whenever the viewport changes
  wrapRootElement: ({ element }) => {
    return <UpdateWrapper element={element} />;
  },
};
